<template>
  <div class="container-fluid" v-if="existePromotor">
    <div class="row" style="width: 100%; margin: 0px">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <nav
          class="navbar navbar-expand-lg navbar-dark bg-dark"
          style="height: 200px; display: block; margin: 0px !important"
        ></nav>
      </div>

      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-4"></div>

      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4">
        <div class="card" style="margin-top: -150px">
          <div class="card-body">
            <div class="align-items-center text-center">
              <span class="text-primary">
                <img
                  src="../../src/assets/img/Xtrim/xtrim_logo_morado.svg"
                  style="width: 200px; margin-bottom: 10px"
                />
              </span>

              <Skeletor
                v-if="loading"
                :shimmer="true"
                height="50"
                width="100%"
                style="margin-bottom: 10px"
              />
            </div>

            <div class="row" v-if="loading">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <Skeletor
                  :shimmer="true"
                  height="20"
                  width="100%"
                  pill
                  style="margin-bottom: 10px"
                />
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5"
                style="margin-bottom: 10px"
              >
                <Skeletor
                  :shimmer="true"
                  height="20"
                  width="50%"
                  pill
                  style="margin-bottom: 10px"
                />
                <Skeletor
                  :shimmer="true"
                  height="40"
                  width="100%"
                  style="margin-bottom: 10px"
                />
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7"
                style="margin-bottom: 10px"
              >
                <Skeletor
                  :shimmer="true"
                  height="20"
                  width="50%"
                  pill
                  style="margin-bottom: 10px"
                />
                <Skeletor
                  :shimmer="true"
                  height="40"
                  width="100%"
                  style="margin-bottom: 10px"
                />
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                style="margin-bottom: 10px"
              >
                <Skeletor
                  :shimmer="true"
                  height="20"
                  width="50%"
                  pill
                  style="margin-bottom: 10px"
                />
                <Skeletor
                  :shimmer="true"
                  height="40"
                  width="100%"
                  style="margin-bottom: 10px"
                />
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                style="margin-bottom: 10px"
              >
                <Skeletor
                  :shimmer="true"
                  height="20"
                  width="50%"
                  pill
                  style="margin-bottom: 10px"
                />
                <Skeletor
                  :shimmer="true"
                  height="40"
                  width="100%"
                  style="margin-bottom: 10px"
                />
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                align="center"
              >
                <Skeletor
                  :shimmer="true"
                  height="40"
                  width="100%"
                  style="margin-bottom: 10px"
                />
              </div>
            </div>

            <div v-else-if="bienvenido" align="center">
              <v-lazy-image
                :src="photo"
                class="rounded-circle"
                style="
                  background: white;
                  width: 100px;
                  margin-bottom: 10px;
                  margin-top: 10px;
                "
              />

              <h1
                class="invitacion_txt"
                style="
                  margin-bottom: 20px;
                  font-size: 20px;
                  line-height: 30px;
                  text-align: center;
                "
              >
                ¡Hola! ¿Cómo estás? Te saluda
                <span
                  style="
                    color: #7d0c7e;
                    font-weight: bold;
                    text-transform: capitalize;
                  "
                  >{{ displayName }}
                </span>
                y quiero invitarte a que te conectes al futuro con el Mejor Plan
                de Internet del Ecuador <br />
                <br />¡<span
                  style="
                    color: #7d0c7e;
                    font-weight: bold;
                    text-transform: capitalize;
                  "
                  >Xtrim</span
                >, un mundo de posiblidades! <br /><br />
              </h1>

              <!-- <h1
                class="invitacion_txt"
                style="margin-bottom: 15px; font-size: 20px; text-align: center"
              >
                ¿Quieres que te contactemos?
              </h1> -->

              <div class="row">
                <div
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  align="center"
                >
                  <button
                    @click="continuar()"
                    class="btn btn-primary"
                    style="width: 100%; font-size: 18px"
                  >
                    Sí quiero el Internet de Xtrim 😀
                  </button>
                </div>
              </div>
            </div>

            <div class="modal__content5" v-else>
              <form-venta
                :key="componentKeyFormVenta"
                :promo-code="promoCode"
                :token-auth="tokenAuth"
                :isPublic="true"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 col-sm-12 col-md-2 col-lg-4 col-xl-4"></div> -->
      <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2"></div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center align-items-center justify-content-xl-between"
        style="color: black !important; margin-top: 15px"
      >
        <hr class="horizontal dark" />

        <a
          target="_blank"
          title="Facebook de XTrimPro"
          href="https://www.facebook.com/profile.php?id=100090370913056"
        >
          <i class="fab fa-facebook-f redes" aria-hidden="true"></i>
        </a>
        <a
          target="_blank"
          title="Instagram de XTrimPro"
          href="https://www.instagram.com/xtrimpro.ec/"
        >
          <i class="fab fa-instagram redes" aria-hidden="true"></i>
        </a>
        <a
          target="_blank"
          title="Email de XTrimPro"
          href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
        >
          <i class="fas fa-envelope redes" aria-hidden="true"></i>
        </a>
        <a
          target="_blank"
          title="Tiktok de XTrimPro"
          href="https://www.tiktok.com/@xtrimproec"
        >
          <svg
            style="margin-bottom: 10px"
            xmlns="http://www.w3.org/2000/svg"
            fill="#7d0c7e"
            widht="10"
            height="22"
            viewBox="0 0 448 512"
          >
            <path
              d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
            /></svg
        ></a>

        <p style="margin-top: 10px; margin-bottom: 0; font-size: 12px">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fterminos_final.pdf?alt=media&token=e822415d-b913-4b78-977d-97dd918ed54b"
            target="_blank"
            >Términos y condiciones</a
          >
          -
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fpolitica-xtrim.pdf?alt=media&token=8bd4a1e6-a1e6-4903-a7d9-216420375b04"
            target="_blank"
            >Política de privacidad</a
          >
          -
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Faviso_legal_xtrimpro.pdf?alt=media&token=ee320100-198f-4fef-9872-eef9d6bd5739"
            target="_blank"
            >Aviso legal</a
          >-
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2FDisclaimer-xtrim.pdf?alt=media&token=d313f2c4-7c0f-42ca-b6e2-fb0f7e1e2767"
            target="_blank"
            >Disclaimer</a
          >
        </p>

        <p style="margin-top: 0px; font-size: 12px; margin-bottom: 10px">
          Copyright &copy; {{ year }}
          <a href="https://xtrimproec.com" target="_blank">- XTrimProEC</a>
        </p>
      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

      <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
        <a class="float" :href="textAyuda" target="_blank">
          <svg
            width="30"
            class="my-float"
            style="margin-top: 14px"
            height="30"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentcolor"
          >
            <path
              d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div v-else class="row" style="width: 100%; margin: 0px">
    <not-promotor />
  </div>
</template>

<script>
/*eslint-disable */

import NotPromotor from "./NotPromotor";
import FormVenta from "./FormVenta";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/app-check";

export default {
  name: "Venta",
  components: {
    FormVenta,
    NotPromotor,
  },
  data() {
    let promoCode = this.$route.params.promoCode;

    return {
      existePromotor: true,
      year: new Date().getFullYear(),
      appCheck: null,
      tokenAuth: null,
      loading: true,
      photo: "",
      phone: null,
      promoCode: promoCode,
      componentKeyFormVenta: 0,
      displayName: "",
      bienvenido: false,
      appCheck: null,
      textAyuda:
        "https://api.whatsapp.com/send?phone=593995756176&text=Hola,%20deseo%20obtener%20más%20información%20de%20XTRIMPRO",
    };
  },
  methods: {
    continuar() {
      this.bienvenido = false;
    },

    getInfo() {
      this.loading = true;

      this.$https
        .post("/acceso/getJoinMyTeam/", {
          promoCode: this.promoCode,
          tokenAuth: this.tokenAuth,
        })
        .then(async (response) => {
          if (response.data.code == 200) {
            console.log("La data", response.data.datos[0]);
            let data = response.data.datos[0];
            this.displayName = data.displayName.toLowerCase();
            this.phone = data.phone;
            this.textAyuda = `https://api.whatsapp.com/send?phone=${data.phone.slice(
              1
            )}&text=Hola,%20deseo%20obtener%20más%20información%20de%20XTRIMPRO`;

            if (response.data.photo === "") {
              this.photo = "/img/user.svg";
            } else {
              this.photo = data.photo;
            }

            let array = this.displayName.split(" ");
            if (Number(array.length) === Number("2")) {
              this.displayName = array[1] + " " + array[0];
            } else {
              if (Number(array.length) === Number("3")) {
                this.displayName = array[2] + " " + array[0];
              } else {
                if (Number(array.length) === Number("4")) {
                  this.displayName = array[2] + " " + array[0];
                }
              }
            }
            this.loading = false;
            this.bienvenido = true;
          } else {
            if (response.data.code == 204) {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              this.existePromotor = false;
            } else {
              /* this.getInfo(); */
            }
          }
        })
        .catch((error) => {
          /* this.getInfo(); */
        });
    },
  },
  computed: {},
  watch: {},
  async mounted() {
    document.title = "XtrimPro - Link de venta ";

    this.$eventBus.on("reiniciarFormVenta", () => {
      this.componentKeyFormVenta += 1;
    });

    firebase.auth().useDeviceLanguage();

    this.appCheck = firebase.appCheck();

    this.appCheck.activate(
      "6LcmjzApAAAAALWdBKUfCTTv_cwg2KXPqGESp5TD",

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      true
    );

    let tokenAux = await this.appCheck.getToken();

    this.tokenAuth = tokenAux.token;

    console.log("Token firebase", this.tokenAuth);

    this.appCheck.setTokenAutoRefreshEnabled(true);
    this.getInfo();
  },

  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.container-fluid {
  padding: 0px;
  margin: 0px;
}

.bg-dark {
  background: linear-gradient(87deg, #591361, #7d0c7e) !important;
  height: 160px;
}
</style>
